function isUnixTimeMoreThanNMinutes(date1, date2, n) {
  // Convert n minutes to milliseconds
  const nMinutesInMs = n * 60 * 1000;

  // Convert Unix timestamps from seconds to milliseconds
  const time1 = date1 * 1000;
  const time2 = date2 * 1000;

  // Calculate the absolute difference between the two dates
  const timeDifference = Math.abs(time1 - time2);

  // Return true if the difference is greater than n minutes
  return timeDifference > nMinutesInMs;
}

export { isUnixTimeMoreThanNMinutes };
