import React, { useEffect, useState } from "react";
import {
  Box,
  IconButton,
  Stack,
  Typography,
  CircularProgress,
  TextField,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  Tabs,
  Tab,
  Alert,
} from "@mui/material";
import { Sync } from "@mui/icons-material";
import axios from "axios";
import {
  isArrayWithValues,
  EmptyState,
  formatServerValue,
} from "../../../../helper";
import { formatDate } from "jwero-javascript-utils";
import TabPanel from "../../../flows/pages/addFlow/helper/chatbot-setting/helper/TabPanel";
import { useSelector } from "react-redux";

const CRMInteractions = () => {
  const today = new Date().toISOString().split("T")[0]; // Get today's date in YYYY-MM-DD format
  const yesterday = new Date(new Date().setDate(new Date().getDate() - 1))
    .toISOString()
    .split("T")[0];

  const [selectedDate, setSelectedDate] = useState(yesterday); // Default to today's date
  const [value, setValue] = useState(0); // Tab value
  const [crmInteractions, setCrmInteractions] = useState([]);
  const [whatsappInteractions, setWhatsappInteractions] = useState([]);
  const [fetching, setFetching] = useState(false);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1); // Track the current page
  const [totalPages, setTotalPages] = useState(1); // Track the total number of pages

  const runoIntegration = useSelector(
    (state) => state.settings.runoIntegration
  );

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setCurrentPage(1); // Reset page to 1 when switching tabs
    setError(null); // Clear any errors
  };

  const fetchInteractions = async (page = 1) => {
    setFetching(true);
    setError(null);

    const endpoint =
      value === 0
        ? "https://api.runo.in/v1/crm/interactions"
        : "https://api.runo.in/v1/crm/whatsapp";
    const params = {
      date: selectedDate,
      pageNo: page,
      processName: "all",
    };

    if (value === 0) {
      params.isLatestOnly = false;
    }

    try {
      const response = await axios.get(endpoint, {
        headers: {
          "Auth-Key": runoIntegration?.auth_key, // Replace with your actual Auth-Key
        },
        params,
      });

      if (response?.data?.statusCode !== 0) {
        throw new Error(
          "Failed to fetch interactions. Please try again later."
        );
      }

      const { data, metadata } = response.data.data;
      if (!isArrayWithValues(data)) {
        throw new Error("No data received or data format is incorrect.");
      }

      if (value === 0) {
        setCrmInteractions(data);
      } else {
        setWhatsappInteractions(data);
      }

      setCurrentPage(page);
      setTotalPages(
        metadata[0].total > 100 ? Math.ceil(metadata[0].total / 100) : 1
      );
    } catch (error) {
      console.error("Error fetching interactions:", error);
      setError(
        "Something went wrong while fetching interactions. Please try again later."
      );
    } finally {
      setFetching(false);
    }
  };

  useEffect(() => {
    fetchInteractions(currentPage);
  }, [selectedDate, value]);

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      fetchInteractions(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      fetchInteractions(currentPage - 1);
    }
  };

  return (
    <Box sx={{ p: 2, px: 4 }}>
      <Stack
        direction={"row"}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <Typography variant="h6">Interactions</Typography>
        <Stack direction="row" alignItems="center" spacing={2}>
          <TextField
            type="date"
            size="small"
            value={selectedDate}
            onChange={(e) => setSelectedDate(e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              max: yesterday, // Restrict to yesterday's date or earlier
            }}
          />
          <IconButton
            onClick={() => fetchInteractions(currentPage)}
            disabled={fetching}
          >
            {fetching ? <CircularProgress size={24} /> : <Sync />}
          </IconButton>
        </Stack>
      </Stack>
      <Typography sx={{ fontStyle: "italic", mt: 2 }}>
        Find interactions for a specific date
      </Typography>
      {error ? (
        <Typography color="error">{error}</Typography>
      ) : (
        <>
          <Box sx={{ width: "100%", mt: 3 }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="interaction tabs"
              >
                <Tab label="CRM" />
                <Tab label="Whatsapp" />
              </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
              <CRMInteractionList rows={crmInteractions} />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <WhatsappInteractionList rows={whatsappInteractions} />
            </TabPanel>
          </Box>
          <Stack direction="row" justifyContent="center" spacing={2} mt={2}>
            <Button
              variant="outlined"
              disabled={currentPage === 1}
              onClick={handlePreviousPage}
            >
              Previous
            </Button>
            <Typography variant="subtitle2" align="center">
              Page {currentPage} of {totalPages}
            </Typography>
            <Button
              variant="outlined"
              disabled={currentPage === totalPages}
              onClick={handleNextPage}
            >
              Next
            </Button>
          </Stack>
        </>
      )}
    </Box>
  );
};

export default CRMInteractions;

const CRMInteractionList = ({ rows }) => {
  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Customer ID</TableCell>
            <TableCell>Name</TableCell>
            <TableCell>Assigned To</TableCell>
            <TableCell>Phone Number</TableCell>
            <TableCell>Priority</TableCell>
            <TableCell>Status</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {isArrayWithValues(rows) ? (
            rows.map((row) => (
              <TableRow
                key={row.customer.id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  <Typography variant="subtitle2">{row.customer.id}</Typography>
                </TableCell>
                <TableCell>
                  <Typography
                    variant="h6"
                    sx={{ fontSize: "13px", whiteSpace: "nowrap" }}
                  >
                    {row.customer.name}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="subtitle2">{row.assigned.to}</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="subtitle2">
                    {row.customer.phoneNumber}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="subtitle2">{row.priority}</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="subtitle2">
                    {formatServerValue(
                      row.userFields.find((field) => field.name === "status")
                        ?.value
                    )}
                  </Typography>
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell colSpan={6}>
                <EmptyState text={"No CRM Interactions Found!"} />
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const WhatsappInteractionList = ({ rows }) => {
  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Customer Name</TableCell>
            <TableCell>Phone Number</TableCell>
            <TableCell>Message</TableCell>
            <TableCell>Label</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Handled By</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {isArrayWithValues(rows) ? (
            rows.map((row) => (
              <TableRow
                key={row.customer.phoneNumber}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  <Typography variant="subtitle2">
                    {row.customer.name}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography
                    variant="h6"
                    sx={{ fontSize: "13px", whiteSpace: "nowrap" }}
                  >
                    {row.customer.phoneNumber}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="subtitle2">{row.message}</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="subtitle2">{row.label}</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="subtitle2">
                    {formatServerValue(row.status)}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="subtitle2">{row.user.name}</Typography>
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell colSpan={6}>
                <EmptyState text={"No Whatsapp Interactions Found!"} />
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
