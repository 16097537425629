import { useEffect, useRef, useState } from "react";
import { Panel, useNodeId } from "reactflow";
import { useDispatch, useSelector } from "react-redux";
import { savingData } from "../helper/SaveElements";
import {
  Box,
  Button,
  IconButton,
  Alert,
  Dialog,
  DialogContent,
  useTheme,
  useMediaQuery,
  DialogActions,
} from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import Chatbot from "../chatbot/index";
import TuneIcon from "@mui/icons-material/Tune";
import { updateNodesWithRef } from "../utils/addReference";
import ModeSwitch from "../helper/ModeSwitch";
import BoltIcon from "@mui/icons-material/Bolt";
import PluginPopover from "../helper/PluginPopver";
import OfflineBoltIcon from "@mui/icons-material/OfflineBolt";
// import ElectricBoltIcon from "@mui/icons-material/ElectricBolt";
import ViewSidebarIcon from "@mui/icons-material/ViewSidebar";
import { panelStyles } from "../styles/panelStyles";
import ChatbotSetting from "../helper/chatbot-setting";
import { EDIT_ELEMENT_IN_REDUX } from "../../../../../redux/actions/flowsActions";
import * as actions from "../../../../../redux/actions/flowsActions";
import CustomTooltip from "./CustomTooltip";
import CampaignSave from "./CampaignSave";
import {
  backendApiCall,
  backendApiEndpoints,
  dialogStyle,
  getTenantId,
  isDev,
  settingsEndpoint,
} from "../../../../../helper";
import { useNavigate } from "react-router-dom";
import { setGlobalToast } from "../../../../../redux/actions/helperActions";
import {
  isArrayWithValues,
  isObjWithValues,
  validateNumber,
} from "jwero-javascript-utils";
import { FLOW_TYPE_LABELS, FLOW_TYPE_NAVIGATION } from "../../../constants";
import SelectCustomer from "../../../../components/SelectCustomer";
import { LoadingButton } from "@mui/lab";
import { DEFAULT_CREATE_TRIGGER_TITLE } from "..";

const styles = panelStyles;

const PanelComponent = ({ id }) => {
  const userId = useSelector((state) => state.flow?.currentNodeId);
  const dispatch = useDispatch();
  const nodes = useSelector((state) => state.flow?.nodes);
  const edges = useSelector((state) => state.flow?.edges);
  const title = useSelector((state) => state.flow?.title);
  const flowChannels = useSelector((state) => state.flow?.flowChannels);
  const flow_details = useSelector((state) => state.flow?.flow_details);
  const chabotMinimized = useSelector((state) => state.flow?.isMinimized);
  const is_trigger_saved = useSelector((state) => state.flow?.is_trigger_saved);
  const actionsMenu = useSelector((state) => state.flow?.actionsMenu);
  const chatbotType = useSelector((state) => state.flow?.chatbotType);
  const fetchingFlow = useSelector((state) => state.flow?.fetchingFlow);

  // const selectedTriggers = nodes.find((it) => it?.id == nodeId)?.fieldData
  //   ?.selected_triggers;
  const [showSuccess, setShowSuccess] = useState(false);
  const [data, setData] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [actionsAnchor, setActionsAnchor] = useState(null);
  const [chatbot, setChatbot] = useState(false);
  const [openSetting, setOpenSetting] = useState(false);
  const [open, setOpen] = useState(false);
  const [saving, setSaving] = useState(false);
  const [showSelectCustomer, setShowSelectCustomer] = useState(false);
  const navigate = useNavigate();
  const theme = useTheme();
  const isIpad = useMediaQuery(theme.breakpoints.down("md"));
  const [selectedTriggerTests, setSelectedTriggerTests] = useState([]);
  const [selectedTriggers, setSelectedTriggers] = useState([]);
  const triggersList = useSelector((state) => state?.marketing?.triggers);

  const pluginRef = useRef();

  useEffect(() => {
    dispatch({
      type: EDIT_ELEMENT_IN_REDUX,
      payload: { is_trigger_saved: false },
    });
  }, [nodes]);

  useEffect(() => {
    const currentNode = nodes?.find(
      (it) => it.id === userId || it?.type === "start"
    );
    // getCustomerSegmemts();
    const fieldData = currentNode?.fieldData;
    if (fieldData?.selected_triggers) {
      setSelectedTriggers(fieldData?.selected_triggers);
    } else {
      setSelectedTriggers([]);
    }
  }, [userId, flow_details]);

  useEffect(() => {
    pluginRef?.current?.setPreForm(flowChannels);
  }, [flow_details]);

  const onSave = async () => {
    if (chatbotType?.type === "campaign") {
      dispatch({
        type: actions.EDIT_ELEMENT_IN_REDUX,
        payload: { actionsMenu: false },
      });
      setOpen(true);
      console.log("CLICKED");
      return;
    }

    if (validateNumber(triggersList?.length) >= 10 && !id) {
      return dispatch(
        setGlobalToast({
          show: true,
          message: "Max triggers limit reached",
          severity: "error",
        })
      );
    }

    if (title === DEFAULT_CREATE_TRIGGER_TITLE || !title?.trim())
      return dispatch(
        setGlobalToast({
          show: true,
          message: "Please enter a title",
          severity: "error",
        })
      );

    try {
      setChatbot(false);
      let data = {
        ...(id ? flow_details : {}),
        title: title,
        nodes: nodes,
        edges: edges,
        channels: flowChannels,
        type: chatbotType?.type === "trigger" ? "trigger" : "chatbot",
        event_name: selectedTriggers?.[0]?.value,
        // theme_mode: mode,
      };
      delete data?.analytics;
      data.nodes = updateNodesWithRef(nodes, edges);
      let response;
      setSaving(true);
      if (!validateNumber(id)) {
        response = await backendApiCall({
          endpoint: `flow/`,
          method: "POST",
          data: data,
        });
      } else {
        response = await backendApiCall({
          endpoint: `flow/${id}`,
          method: "PUT",
          data: data,
        });
      }
      if (!response?.success) throw new Error();
      // await settingsEndpoint({
      //   endpoint: `${backendApiEndpoints.settings}/is_trigger_enabled`,
      //   method: "PUT",
      //   data: {
      //     [selectedTriggers?.[0]?.value]: data?.status === "publish",
      //   },
      // });
      dispatch(
        setGlobalToast({
          show: true,
          message: `${FLOW_TYPE_LABELS[chatbotType?.type]} has been saved`,
          severity: "success",
        })
      );
      dispatch({
        type: EDIT_ELEMENT_IN_REDUX,
        payload: { is_trigger_saved: true },
      });
      if (
        response?.success &&
        isObjWithValues(response?.data) &&
        response?.data?.hasOwnProperty("id")
      ) {
        navigate(
          `/${FLOW_TYPE_NAVIGATION[chatbotType?.type]}/${response?.data?.id}`
        );
      }
      // savingData(nodes, edges);
      // setShowSuccess(true);
      // setTimeout(() => {
      //   setShowSuccess(false);
      // }, 1000);
    } catch (error) {
      console.error(error);
      dispatch(
        setGlobalToast({
          show: true,
          message: `Unable to update ${
            FLOW_TYPE_LABELS[chatbotType?.type]
          }, Please try again!`,
          severity: "success",
        })
      );
    } finally {
      console.log("SAVED");
      setSaving(false);
    }
  };

  const handleChatbot = async () => {
    const data = updateNodesWithRef(nodes, edges);
    setData(data);
    dispatch({
      type: EDIT_ELEMENT_IN_REDUX,
      payload: { isMinimized: false, actionsMenu: false },
    });
  };

  const handlePlugin = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleSetting = () => {
    setOpenSetting(true);
  };

  const openActions = () => {
    dispatch({
      type: actions.EDIT_ELEMENT_IN_REDUX,
      payload: { actionsMenu: !actionsMenu },
    });
  };

  const onTestTriggerCustomerSelect = (customer) => {
    setSelectedTriggerTests(customer);
    // if (!isArrayWithValues(customers))
    //   dispatch(
    //     setGlobalToast({
    //       show: true,
    //       message: "Please select one customer",
    //       severity: "error",
    //     })
    //   );
    // console.log(customers);
  };
  const onTestTriggerCustomerSubmit = async () => {
    if (!isArrayWithValues(selectedTriggerTests))
      return dispatch(
        setGlobalToast({
          show: true,
          message: "Please select one customer",
          severity: "error",
        })
      );
    if (!isArrayWithValues(selectedTriggers))
      return dispatch(
        setGlobalToast({
          show: true,
          message: "Please select a trigger",
          severity: "error",
        })
      );

    console.log(selectedTriggerTests);
    let res;

    // if (isDev()) res = { success: true };
    // else
    let allRes = [];
    for await (let customer of selectedTriggerTests) {
      let res = await backendApiCall({
        endpoint: "webhooks/trigger",
        method: "POST",
        data: {
          event_name: selectedTriggers?.[0]?.value,
          tenant_id: await getTenantId(),
          users: [
            {
              id: customer?.id,
              type: "CRM",
            },
          ],
        },
      });
      allRes.push(res);
    }
    if (allRes?.some((i) => i?.success)) {
      let success = allRes?.filter((i) => i?.success);
      dispatch(
        setGlobalToast({
          show: true,
          message: `Trigger started on ${success?.length}/${allRes?.length} customers`,
          severity: "success",
        })
      );
      setSelectedTriggerTests([]);
      setShowSelectCustomer(false);
    } else {
      // let success = allRes?.filter((i) => i?.success);
      dispatch(
        setGlobalToast({
          show: true,
          message: `Unable to run trigger please try again`,
          severity: "error",
        })
      );
    }
  };

  const onTestTrigger = () => {
    if (!is_trigger_saved)
      return dispatch(
        setGlobalToast({
          show: true,
          message: "Save your Trigger before you trigger it",
          severity: "info",
        })
      );
    setShowSelectCustomer(true);
  };

  return (
    <>
      <Panel position="top-right">
        <Box sx={styles.panelBox}>
          <CustomTooltip title="Mode" position="top" fontBold={600}>
            <IconButton
              sx={{
                ...styles.pluginIconBox,
                backgroundColor: "white",
                padding: 0,
              }}
            >
              <ModeSwitch />
            </IconButton>
          </CustomTooltip>
          <CustomTooltip title={"Actions"} position={"top"} fontBold={600}>
            <IconButton
              sx={{ ...styles.pluginIconBox, backgroundColor: "white" }}
              onClick={openActions}
            >
              <ElectricBoltIcon
                style={{
                  ...styles.pluginIcon,
                  backgroundColor: "white",
                  color: "black",
                  fontSize: "30px",
                  height: "30px",
                  width: "30px",
                }}
              />
            </IconButton>
          </CustomTooltip>
          {chatbotType?.type === "webchat" && (
            <>
              <CustomTooltip
                title={"Integrations"}
                position={"top"}
                fontBold={600}
              >
                <IconButton onClick={handlePlugin} sx={styles.pluginIconBox}>
                  <TuneIcon sx={{ ...styles.pluginIcon, color: "black" }} />
                </IconButton>
              </CustomTooltip>
              <Button
                sx={styles.testBotButton}
                variant="outlined"
                onClick={handleChatbot}
              >
                Test your Bot
              </Button>
            </>
          )}
          {chatbotType?.type === "trigger" && (
            <>
              <Button
                sx={{ ...styles.testBotButton, whiteSpace: "nowrap" }}
                variant="outlined"
                onClick={onTestTrigger}
              >
                Test your Trigger
              </Button>
            </>
          )}
          <PluginPopover
            ref={pluginRef}
            anchorEl={anchorEl}
            setAnchorEl={setAnchorEl}
          />
          <Button
            sx={{
              textTransform: "none",
              borderRadius: "7px",
              border: "none",
              padding: "7px 15px",
            }}
            variant="contained"
            startIcon={<SendIcon />}
            onClick={onSave}
            disabled={saving || fetchingFlow}
          >
            Save
          </Button>
          {/* </LoadingButton> */}
        </Box>
        <CampaignSave open={open} setOpen={setOpen} />
      </Panel>
      <Panel position="bottom-right">
        {!chabotMinimized && <Chatbot data={data} />}
        <Dialog
          open={showSelectCustomer}
          onClose={() => setShowSelectCustomer(false)}
          maxWidth="sm"
          fullWidth
          sx={isIpad ? {} : dialogStyle}
        >
          <DialogContent>
            <SelectCustomer
              createButton={false}
              multiple
              createOnClick={() => {
                // setAddFormView(true);
                setCreate(true);
                setShowSelectCustomer(false);
              }}
              heading={"Select a Contact"}
              onSelect={onTestTriggerCustomerSelect}
              onClose={() => setShowSelectCustomer(false)}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setShowSelectCustomer(false)}>Cancel</Button>
            <LoadingButton
              variant="contained"
              // disabled={!isObjWithValues(selectedCustomer)}
              // loading={mergingCustomer}
              onClick={onTestTriggerCustomerSubmit}
            >
              Start Trigger ({validateNumber(selectedTriggerTests?.length)}{" "}
              Customers)
            </LoadingButton>
          </DialogActions>
        </Dialog>
        {/* <SelectCustomer
          // createButton={true}
          createOnClick={() => {
            // setAddFormView(true);
            setCreate(true);
            setShowSelectCustomer(false);
          }}
          heading={"Select a customer"}
          onClose={() => setShowSelectCustomer(false)}
          onSelect={onTestTriggerCustomerSelect}
        /> */}
      </Panel>
    </>
  );
};

export default PanelComponent;

const ElectricBoltIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    data-name="Layer 3"
    transform="scale(1 -1)"
    viewBox="0 0 2050 2050"
    {...props}
  >
    <title />
    <path
      d="M1374.1 1622.9a45.1 45.1 0 0 1-39.3-23.1l-255.1-457.4-268.9 94.9a44.9 44.9 0 0 1-59.5-35.7l-110.6-735a45 45 0 0 1 82.1-31.5l258.7 392.6L1248 719.9a45.1 45.1 0 0 1 61.5 35.8l109.2 816.2a44.9 44.9 0 0 1-30.4 48.7 43.1 43.1 0 0 1-14.2 2.3Z"
      style={{
        fill: "#4d4d4d",
      }}
    />
  </svg>
);
