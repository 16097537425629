import { isArrayWithValues } from "jwero-javascript-utils";

export const getComponents = ({ template, all_attributes }) => {
  const result = [];

  const fileType = {
    // TEXT: "text",
    VIDEO: "video",
    IMAGE: "image",
    DOCUMENT: "document",
  };

  for (const component of template.components) {
    console.log(component, " <<<<<<<<<<<<<<<<<<<<< component");
    const processedComponent = {
      type: component?.type,
      parameters: [],
    };

    switch (component?.type) {
      case "HEADER":
        if (component.format?.toLowerCase() === "text") {
          let attribute = component?.selected_attributes?.[0];
          if (
            all_attributes?.find(
              (i) => i?.value === attribute || i?.label === attribute
            )
          )
            attribute = `{{${attribute}}}`;
          if (attribute)
            processedComponent?.parameters.push({
              type: "text",
              text: attribute,
            });
          // processedComponent.parameters.push({
          //   type: "TEXT",
          //   text: component.selected_attributes?.[0],
          // });
        } else if (fileType[component.format]) {
          processedComponent.parameters.push({
            type: fileType[component.format],
            [fileType[component.format]]: {
              link:
                component?.file_url?.url ||
                component?.example?.header_handle?.[0],
            },
          });
        }
        break;

      case "BODY":
        if (
          component?.example?.body_text &&
          component?.example?.body_text.length > 0
        ) {
          component?.example?.body_text[0].forEach((text, index) => {
            let attribute = component?.selected_attributes?.[index];
            if (
              all_attributes?.find(
                (i) => i?.value === attribute || i?.label === attribute
              )
            )
              attribute = `{{${attribute}}}`;
            processedComponent?.parameters.push({
              type: "text",
              text: attribute || text,
            });
          });
        }
        break;

      // case "FOOTER":
      //     processedComponent.parameters.push({
      //         type: "text",
      //         text: component.text
      //     });
      //     break;

      // case "BUTTONS":
      //     if (component.buttons && component.buttons.length > 0) {
      //         processedComponent.parameters = component.buttons.map((button) => {
      //             switch (button.type) {
      //                 case "URL":
      //                     return {
      //                         type: "url",
      //                         text: button.text,
      //                         url: button.url
      //                     };
      //                 case "PHONE_NUMBER":
      //                     return {
      //                         type: "phone_number",
      //                         text: button.text,
      //                         phone_number: button.phone_number
      //                     };
      //                 case "QUICK_REPLY":
      //                     return {
      //                         type: "quick_reply",
      //                         text: button.text
      //                     };
      //                 default:
      //                     return null;
      //             }
      //         }).filter(Boolean);
      //     }
      //     break;

      default:
        continue;
    }

    if (isArrayWithValues(processedComponent?.parameters))
      result.push(processedComponent);
  }

  return result;
};

export const createTemplateForBackend = ({
  id,
  templates,
  currentTemplate,
  all_attributes,
}) => {
  const currentUnchangeTemplate = templates.find(
    (template) => template.id + "" === id + ""
  );
  const createdComponents = getComponents({
    template: currentTemplate,
    all_attributes,
  });
  const data = {
    platform: "whatsapp",
    payload: {
      messaging_product: "whatsapp",
      recipient_type: "individual",
      // to: "918097534684",
      type: "template",
      template: {
        name: currentTemplate?.name || currentUnchangeTemplate?.name,
        language: {
          code: currentTemplate?.language || currentUnchangeTemplate?.language,
        },
        components: createdComponents,
      },
    },
    meta_data: {
      template: currentUnchangeTemplate,
    },
  };

  return data;
};
