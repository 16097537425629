import {
  Box,
  Button,
  Chip,
  Stack,
  Skeleton,
  CircularProgress,
  Popover,
  useMediaQuery,
  TextField,
  InputAdornment,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import DeleteIcon from "@mui/icons-material/Delete";
import FilterListIcon from "@mui/icons-material/FilterList";
import { visuallyHidden } from "@mui/utils";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllOrders,
  getOrderStatuses,
} from "../../redux/actions/orderActions";
import {
  formatDate,
  isArrayWithValues,
  formatServerValue,
  isObjWithValues,
  validateNumber,
  formatDateDynamic,
} from "../../helper";
import { useTheme } from "@mui/system";
import { useNavigate } from "react-router-dom";
import { CalendarToday, Language } from "@mui/icons-material";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { isValidDate } from "../../utils/dateFunctions";
import DataNotFound from "../components/DataNotFound";
import {
  CatalogIcon,
  DigigoldMenuSvg,
  OrdersIcon,
  ShopifySvg,
  UnicommerceSvg,
  WhatsappSvg,
  WooCommerceSvg,
} from "../../Svgs";
import { CustomDateRangeFilter } from "../digital_gold/Dashboard";
import { CornerDownLeft } from "react-feather";
import HorizontalStack from "../../helper/utility/HorizontalStack";
import ExportAsSheets from "./helpers/ExportAsSheets";
import { currencySymbols } from "../pricing/Gold";

const defaultParams = {
  per_page: 75,
  page: 1,
};

export const allPaymentStatus = [
  { label: "All", value: "" },
  { label: "Pending", value: "pending" },
  { label: "Processing", value: "processing" },
  { label: "On Hold", value: "on-hold" },
  { label: "Completed", value: "completed" },
  { label: "Cancelled", value: "cancelled" },
  { label: "Failed", value: "failed" },
  { label: "Refunded", value: "refunded" },
];

const tabs = [
  {
    label: "All Orders",
    value: "all_orders",
  },
  {
    label: "Website",
    value: "website",
    icon: <Language />,
  },
  {
    label: "Whatsapp",
    value: "whatsapp",
    icon: <WhatsappSvg style={{ height: "20px", width: "20px" }} />,
  },
  {
    label: "Shopify",
    value: "shopify",
    icon: <ShopifySvg style={{ height: "20px", width: "20px" }} />,
  },
  {
    label: "WooCommerce",
    value: "woocommerce",
    icon: <WooCommerceSvg style={{ height: "20px", width: "20px" }} />,
  },
  {
    label: "Catalog",
    value: "catalog",
    icon: <CatalogIcon style={{ height: "20px", width: "20px" }} />,
  },

  {
    label: "Digital gold",
    value: "digigold",
    icon: <DigigoldMenuSvg style={{ height: "20px", width: "20px" }} />,
  },
  // { label: "App", value: "app" },
  // { label: "Instore", value: "instore" },
  // { label: "Whatsapp", value: "whatsapp" },
];

const tabsByPlans = {
  catalog: ["catalog"],
};

function Orders() {
  const [selectedTab, setSelectedTab] = useState("all_orders");
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(20);
  const [rows, setRows] = useState([]);
  const [params, setParams] = useState(defaultParams);
  const [integratedChannels, setIntegratedChannels] = useState([]);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const allOrders = useSelector((state) => state.orders.allOrders);
  const fetchingOrders = useSelector((state) => state.orders.fetchingOrders);
  const noOrders = useSelector((state) => state.orders.noOrders);
  const allOrdersFetched = useSelector(
    (state) => state.orders.allOrdersFetched
  );
  const reduxShopifyIntegration = useSelector(
    (state) => state.settings.shopifyIntegration
  );

  const reduxWooCommerceIntegration = useSelector(
    (state) => state.settings.wooCommerceIntegration
  );

  const theme = useTheme();
  const isDarkTheme = theme.palette && theme.palette.mode === "dark";

  const fetchedAllOrders = useSelector(
    (state) => state.orders.fetchedAllOrders
  );
  const user_products = useSelector(
    (state) => state.user?.store_plan?.user_products
  );
  const storePlan = useSelector((state) => state.user.store_plan);
  const totalOrders = useSelector((state) => state.orders.totalOrders);

  useEffect(() => {
    let array = ["all_orders"];
    // !(
    //     user_products?.length === 1 && user_products.includes("crm")
    //   );

    if (storePlan?.plan === "growth") {
      array.push("digigold");
      array.push("whatsapp");
      array.push("website");
      array.push("catalog");
    }
    if (user_products?.includes("e-commerce")) {
      array.push("website");
      array.push("catalog");
    }
    if (user_products?.includes("crm")) {
      array.push("website");
      array.push("catalog");
    }
    if (user_products?.includes("digital_gold")) {
      array.push("digigold");
    }
    if (user_products?.includes("catalog")) {
      // array.push("website");
      array.push("catalog");
    }
    if (user_products?.includes("chats_pro")) array.push("whatsapp");

    if (reduxShopifyIntegration?.integrated) array.push("shopify");
    if (reduxWooCommerceIntegration?.integrated) array.push("woocommerce");

    setIntegratedChannels([...new Set(array)]);
  }, [reduxShopifyIntegration, user_products, reduxWooCommerceIntegration]);

  useEffect(() => {
    if (!isArrayWithValues(allOrders) && !allOrdersFetched) {
      dispatch(getAllOrders(params));
    }
  }, []);

  useEffect(() => {
    if (Array.isArray(allOrders)) setRows(allOrders);
  }, [allOrders]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.name);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    let shouldFetch = newPage === parseInt(rows.length / rowsPerPage) - 1;
    console.log(shouldFetch);
    if (shouldFetch && !fetchingOrders && !allOrdersFetched) {
      dispatch(getAllOrders({ ...params, page: params.page + 1 }));
      setParams((state) => ({ ...state, page: state.page + 1 }));
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const onTabPress = (obj) => {
    setSelectedTab(obj.value);
    let filterObj = {};
    let paramsObj = { ...params };
    let deletableKeys = Object.keys(paramsObj).filter((i) =>
      i.includes("custom_filter")
    );
    for (let key of deletableKeys) delete paramsObj[key];
    if (obj.value !== "all_orders") {
      if (obj.value === "digigold") {
        filterObj[`custom_filter[${0}][key]`] = "virtual_order";
        filterObj[`custom_filter[${0}][value]`] = obj.value;
      } else if (obj.value === "catalog") {
        filterObj[`custom_filter[${0}][key]`] = "source";
        filterObj[`custom_filter[${0}][value]`] = obj.value;
      } else if (obj.value === "whatsapp") {
        filterObj[`custom_filter[${0}][key]`] = "order_channel";
        filterObj[`custom_filter[${0}][value]`] = "whatsapp";
        filterObj[`custom_filter[${0}][compare]`] = "like";
      } else {
        // filterObj[`custom_filter[${0}][key]`] = "order_channel";
        // filterObj[`custom_filter[${0}][value]`] = "";
        // filterObj[`custom_filter[${0}][compare]`] = "==";
        filterObj[`custom_filter[${0}][key]`] = "order_channel";
        filterObj[`custom_filter[${0}][compare]`] = "NOT EXISTS";
      }
    }
    setParams({ ...filterObj, ...paramsObj, ...defaultParams });
    dispatch(
      getAllOrders({ ...filterObj, ...paramsObj, ...defaultParams }, true)
    );
  };
  const onKeyDown = (e) => {
    if (e.key === "Enter") dispatch(getAllOrders({ ...params }, true));
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  // if (noOrders)
  //   return (
  //     <DataNotFound
  //       illustration={<img src="/gifs/orders.gif" />}
  //       title="Look’s like you have no orders currently."
  //       subtitle={
  //         "You can see your orders and analytics listed here. you can view details, process orders etc."
  //       }
  //     />
  //   );

  return (
    <Box>
      <Stack
        direction="row"
        sx={{
          backgroundColor: "background.paper",
          padding: "10px",
          borderBottomColor: "#e8e8e8",
          borderBottomWidth: 0.2,
          borderBottomStyle: "solid",
        }}
        spacing={4}
        className="example-step-1"
      >
        {tabs
          .filter((i) => integratedChannels.includes(i.value))
          .map((i) => {
            return (
              <Button
                variant={"text"}
                // variant={selectedTab !== i.value ? "outlined" : "contained"}
                sx={{
                  backgroundColor:
                    selectedTab === i.value ? "#e6e6e6" : "transparent",
                  color: "#000",
                  // color: selectedTab === i.value ? "primary.main" : "#000",
                  "&:hover": {
                    backgroundColor:
                      selectedTab === i.value ? "#e6e6e6" : "transparent",
                  },
                }}
                onClick={() => onTabPress(i)}
                startIcon={i.icon}
              >
                {i.label}
              </Button>
            );
          })}
      </Stack>
      <Box sx={{ width: "100%" }}>
        <Paper sx={{ width: "100%", mb: 2 }} className="example-step-2">
          <EnhancedTableToolbar
            numSelected={selected.length}
            params={params}
            setParams={setParams}
          />
          <Stack
            direction={"row"}
            alignItems={"center"}
            gap={3}
            flexWrap={"wrap"}
            justifyContent={"space-between"}
            sx={{ px: 4 }}
          >
            <TextField
              size="small"
              placeholder="Search for Orders"
              sx={{ minWidth: "250px" }}
              value={params?.search}
              onChange={(e) => {
                setParams((pre) => ({ ...pre, search: e.target.value }));
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <CornerDownLeft size="18px" />
                  </InputAdornment>
                ),
              }}
              onKeyDown={onKeyDown}
            />

            <TablePagination
              rowsPerPageOptions={[10, 20, 50]}
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Stack>
          {(!totalOrders || totalOrders === "0") && !fetchingOrders && (
            <Box sx={{ mb: "25vh" }}>
              <DataNotFound
                title={
                  <Box sx={{ textAlign: "center" }}>
                    <Typography>No orders found</Typography>
                  </Box>
                }
                illustration={
                  <OrdersIcon style={{ height: "70px", width: "70px" }} />
                }
              />
            </Box>
          )}
          <TableContainer style={{}}>
            <Table
              aria-labelledby="tableTitle"
              size={dense ? "small" : "medium"}
            >
              {(isArrayWithValues(rows) ||
                (totalOrders && totalOrders !== "0")) && (
                <EnhancedTableHead
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={rows.length}
                />
              )}
              <TableBody>
                {fetchingOrders && !isArrayWithValues(rows) && (
                  <>
                    {Array(10)
                      .fill(null)
                      .map((item, index) => (
                        <TableRow
                          key={index}
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                            py: 2,
                            cursor: "pointer",
                          }}
                        >
                          <TableCell padding="checkbox">
                            {/* <Stack
                              direction="row"
                              sx={{ width: "100%" }}
                              alignItems="center"
                            >
                            </Stack> */}
                            <IconButton>
                              <Skeleton
                                variant="rectangular"
                                sx={{
                                  width: "18px",
                                  height: "18px",
                                  borderRadius: "3px",
                                  ml: 1,
                                }}
                              />
                            </IconButton>
                          </TableCell>
                          <TableCell component="th" scope="row">
                            <Skeleton
                              variant="text"
                              sx={{ fontSize: "1rem", width: "100%" }}
                            />
                          </TableCell>

                          <TableCell align="right">
                            <Skeleton
                              variant="text"
                              sx={{
                                fontSize: "1rem",
                                width: "35%",
                                marginLeft: "auto",
                              }}
                            />
                          </TableCell>
                          <TableCell align="right">
                            <Skeleton
                              variant="text"
                              sx={{
                                fontSize: "1rem",
                                width: "35%",
                                marginLeft: "auto",
                              }}
                            />
                          </TableCell>
                          <TableCell align="right">
                            <Skeleton
                              variant="text"
                              sx={{
                                fontSize: "1rem",
                                width: "35%",
                                marginLeft: "auto",
                              }}
                            />
                          </TableCell>
                          <TableCell align="right">
                            <Skeleton
                              variant="text"
                              sx={{
                                fontSize: "1rem",
                                width: "35%",
                                marginLeft: "auto",
                              }}
                            />
                          </TableCell>
                          <TableCell align="right">
                            <Skeleton
                              variant="text"
                              sx={{
                                fontSize: "1rem",
                                width: "35%",
                                marginLeft: "auto",
                              }}
                            />
                          </TableCell>
                          <TableCell align="right">
                            <Skeleton
                              variant="text"
                              sx={{
                                fontSize: "1rem",
                                width: "35%",
                                marginLeft: "auto",
                              }}
                            />
                          </TableCell>
                        </TableRow>
                        //   <TableRow key={index}>
                        //     <TableCell>
                        //       <Skeleton
                        //         variant="text"
                        //         sx={{ fontSize: "1rem", width: "50%" }}
                        //       />
                        //     </TableCell>
                        //     <TableCell>
                        //       <Skeleton
                        //         variant="text"
                        //         sx={{ fontSize: "1rem", width: "50%" }}
                        //       />
                        //     </TableCell>
                        //     <TableCell>
                        //       <Skeleton
                        //         variant="text"
                        //         sx={{ fontSize: "1rem", width: "50%" }}
                        //       />
                        //     </TableCell>
                        //     <TableCell>
                        //       <Skeleton
                        //         variant="text"
                        //         sx={{ fontSize: "1rem", width: "50%" }}
                        //       />
                        //     </TableCell>
                        //     <TableCell>
                        //       <Skeleton
                        //         variant="text"
                        //         sx={{ fontSize: "1rem", width: "50%" }}
                        //       />
                        //     </TableCell>
                        //   </TableRow>
                      ))}
                  </>
                )}
                {stableSort(rows, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    const isItemSelected = isSelected(row.id);
                    const labelId = `enhanced-table-checkbox-${index}`;
                    let name =
                      row.billing &&
                      `${row.billing.first_name || ""} ${
                        row.billing.last_name
                      }`;
                    let date = formatDateDynamic(
                      new Date(row.date_created_gmt + "Z"),
                      "DD MMM, YYYY hh:mm A"
                    );
                    let channelObj = isArrayWithValues(row.meta_data)
                      ? row.meta_data.find((i) => i.key === "virtual_order")
                      : false;
                    let catalog =
                      row?.meta_data?.find((i) => i.key === "source")?.value ===
                      "catalog";
                    let discount_total = validateNumber(row?.discount_total);

                    let unicommerceDetails = row?.meta_data?.find(
                      (i) => i.key === "unicommerce_integration"
                    )?.value;
                    row.currency_symbol = currencySymbols[row?.currency];

                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        sx={{ py: 2, cursor: "pointer" }}
                        tabIndex={-1}
                        aria-checked={isItemSelected}
                        key={row.name}
                        selected={isItemSelected}
                        style={{ padding: "10px" }}
                        onClick={() => navigate(`${row.id}`)}
                      >
                        <TableCell padding="checkbox">
                          {/* <Checkbox
                            color="primary"
                            onClick={(event) => {
                              event.stopPropagation();
                              handleClick(event, row.id);
                            }}
                            checked={isItemSelected}
                            inputProps={{
                              "aria-labelledby": labelId,
                            }}
                          /> */}
                        </TableCell>
                        <TableCell
                          component="th"
                          id={labelId}
                          scope="row"
                          padding="none"
                        >
                          <Stack sx={{ my: 3 }}>
                            <Typography fontSize="15px" fontWeight={"600"}>
                              #{row.id} - {name}
                            </Typography>
                            <Stack flexDirection={"row"}>
                              <Typography mt={1} fontSize="13px">
                                {date}
                              </Typography>
                              {isObjWithValues(unicommerceDetails) && (
                                <Stack
                                  flexDirection={"row"}
                                  marginLeft={4}
                                  alignItems={"center"}
                                  justifyContent={"center"}
                                >
                                  <UnicommerceSvg
                                    width={25}
                                    style={{ marginTop: 4 }}
                                  />
                                  <Typography mt={1} fontSize="13px">
                                    {unicommerceDetails?.status}
                                  </Typography>
                                </Stack>
                              )}
                            </Stack>
                          </Stack>
                        </TableCell>

                        <TableCell
                          align="right"
                          sx={{ fontSize: "14px", fontWeight: "600" }}
                        >
                          <Chip
                            size="small"
                            mr={1}
                            mb={1}
                            label={row.status}
                            // color="warning"
                            sx={{
                              color: statusFontColor(row.status),
                              border: isDarkTheme ? "0.7px solid" : "0px solid",
                              // borderColor: statusFontColor(row.status),
                              backgroundColor: isDarkTheme
                                ? "transparent"
                                : getStatusBackgroundColor(row.status),
                            }}
                            variant="outlined"
                          />
                        </TableCell>

                        <TableCell
                          align="right"
                          sx={{ fontSize: "13px", fontWeight: "600" }}
                        >
                          <Tooltip
                            title={
                              tabs.find(
                                (i) => i.value === getChannelName(row.meta_data)
                              )?.label ||
                              tabs.find((i) => i.value === "website")?.label ||
                              ""
                            }
                          >
                            <div
                              style={{
                                alignSelf: "flex-end",
                                float: "right",
                              }}
                            >
                              {tabs.find(
                                (i) => i.value === getChannelName(row.meta_data)
                              )?.icon ||
                                tabs.find((i) => i.value === "website")?.icon ||
                                ""}
                            </div>
                          </Tooltip>
                        </TableCell>

                        <TableCell align="right">
                          <Stack alignItems={"flex-end"}>
                            <TruncatedText
                              text={
                                row?.meta_data?.find(
                                  (meta) =>
                                    meta?.key ===
                                      "_wc_order_attribution_utm_source" &&
                                    meta?.value
                                )?.value || "-"
                              }
                              fontWeight={600}
                            />
                            <TruncatedText
                              text={
                                row?.meta_data?.find(
                                  (meta) =>
                                    meta?.key ===
                                      "_wc_order_attribution_utm_campaign" &&
                                    meta?.value
                                )?.value || ""
                              }
                              style={{ marginTop: "6px" }} // Equivalent of `mt={1}`
                              fontWeight={300}
                            />
                          </Stack>
                        </TableCell>

                        <TableCell
                          align="right"
                          sx={{ fontSize: "14px", fontWeight: "600" }}
                        >
                          {row?.coupon_lines?.map((coupon) =>
                            coupon?.code?.toUpperCase()
                          )}
                        </TableCell>
                        <TableCell
                          align="right"
                          // sx={{ fontSize: "14px", fontWeight: "600" }}
                        >
                          {discount_total ? (
                            <Stack>
                              <Typography
                                sx={{ textDecorationLine: "line-through" }}
                              >
                                {row.currency_symbol}
                                {validateNumber(row.total) +
                                  validateNumber(discount_total)}
                              </Typography>
                              <Typography
                                sx={{ fontSize: "14px", fontWeight: "600" }}
                              >
                                {row.currency_symbol} {row.total}
                              </Typography>
                            </Stack>
                          ) : (
                            <Typography
                              sx={{ fontSize: "14px", fontWeight: "600" }}
                            >
                              {row.currency_symbol}
                              {row.total}
                            </Typography>
                          )}
                          {/* {row.currency_symbol} {row.total} */}
                        </TableCell>
                        <TableCell align="right">
                          {row.payment_method}
                        </TableCell>
                        {/* <TableCell align="right">{row.carbs}</TableCell> */}
                      </TableRow>
                    );
                  })}
                {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: (dense ? 33 : 53) * emptyRows,
                    }}
                  >
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          {!totalOrders && (
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          )}
          {fetchingOrders && (
            <Stack
              direction={"row"}
              justifyContent="center"
              alignItems={"center"}
              spacing={2}
              sx={{ pb: 3 }}
            >
              <CircularProgress style={{ height: "18px", width: "18px" }} />
              <Typography>Fetching Orders</Typography>
            </Stack>
          )}
        </Paper>
        {/* <FormControlLabel
          control={<Switch checked={dense} onChange={handleChangeDense} />}
          label="Dense padding"
        /> */}
      </Box>
    </Box>
  );
}

export default Orders;

const getChannelName = (meta_data) => {
  let channel = "";
  if (!isArrayWithValues(meta_data)) return channel;
  let virtual_order_obj = meta_data?.find((i) => i.key === "virtual_order");
  if (virtual_order_obj?.value) channel = virtual_order_obj?.value;
  let channel_name = meta_data?.find((i) => i.key === "order_channel")?.value
    ?.name;
  let name = meta_data?.find((i) => i?.key === "source")?.value;
  if (name) channel = name;
  if (channel_name) channel = channel_name;
  return channel;
};

export const getStatusBackgroundColor = (status) => {
  status = status ? status.toLowerCase() : "";
  switch (status) {
    case "completed":
    case "complete":
    case "live":
    case "active":
      return "#b3ecd5";
    case "pending":
    case "draft":
    case "warning":
      return "#f2efc4";
    case "processing":
      return "#EAECFB";
    case "failed":
    case "refunded":
    case "cancelled":
      return "#ffc3d0";
    // return "#ffe0e7";
    // return "#f2e5e8";
    case "on-hold":
    case "pause":
      return "#AAEDF9";
    case "default":
      return "rgba(0, 0, 0, 0.08)";
    case "outlined":
      return "transparent";
    default:
      return "#EAECFB";
    // case "completed":
    //   return "#f7e0d2";
    // case "pending":
    //   return "#f7e0d2";
    // default:
    //   return "#e8e8e8";
  }
};

export const statusFontColor = (status) => {
  status = status ? status.toLowerCase() : "";
  switch (status) {
    case "completed":
    case "complete":
    case "live":
    case "active":
      return "#276b4e";
    case "pending":
    case "draft":
    case "warning":
      return "#546014";
    case "processing":
      return "#5570bc";
    case "failed":
    case "refunded":
    case "cancelled":
      return "#663533";
    // return "#eb6b67";
    // return "#910905";
    case "on-hold":
    case "pause":
      return "#46ADCA";
    case "default":
      return "rgba(0, 0, 0, 0.87)";
    default:
      return "#043b69";
  }
};

function createData(name, calories, fat, carbs, protein) {
  return {
    name,
    calories,
    fat,
    carbs,
    protein,
  };
}

// const rows = [
//   createData("Cupcake", 305, 3.7, 67, 4.3),
//   createData("Donut", 452, 25.0, 51, 4.9),
//   createData("Eclair", 262, 16.0, 24, 6.0),
//   createData("Frozen yoghurt", 159, 6.0, 24, 4.0),
//   createData("Gingerbread", 356, 16.0, 49, 3.9),
//   createData("Honeycomb", 408, 3.2, 87, 6.5),
//   createData("Ice cream sandwich", 237, 9.0, 37, 4.3),
//   createData("Jelly Bean", 375, 0.0, 94, 0.0),
//   createData("KitKat", 518, 26.0, 65, 7.0),
//   createData("Lollipop", 392, 0.2, 98, 0.0),
//   createData("Marshmallow", 318, 0, 81, 2.0),
//   createData("Nougat", 360, 19.0, 9, 37.0),
//   createData("Oreo", 437, 18.0, 63, 4.0),
// ];

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "order",
    numeric: false,
    disablePadding: true,
    label: "Order",
  },
  {
    id: "status",
    numeric: true,
    disablePadding: false,
    label: "Status",
  },
  {
    id: "channel",
    numeric: true,
    disablePadding: false,
    label: "Channel",
  },
  {
    id: "source",
    numeric: true,
    disablePadding: false,
    label: "Source",
  },
  {
    id: "coupons",
    numeric: true,
    disablePadding: false,
    label: "Coupons",
  },
  {
    id: "amount",
    numeric: true,
    disablePadding: false,
    label: "Amount",
  },

  {
    id: "method",
    numeric: true,
    disablePadding: false,
    label: "Method",
  },
  // {
  //   id: "action",
  //   numeric: true,
  //   disablePadding: false,
  //   label: "Action",
  // },
  // {
  //   id: "protein",
  //   numeric: true,
  //   disablePadding: false,
  //   label: "Protein (g)",
  // },
];
const EnhancedTableToolbar = (props) => {
  const storeUsed = useSelector((state) => state.user.store_used);
  const { numSelected, params, setParams } = props;
  const [selectedStatus, setSelectedStatus] = useState("");
  const [orderStatuses, setOrderStatuses] = useState(allPaymentStatus);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const filterOpen = Boolean(anchorEl);
  const dispatch = useDispatch();

  const [dateAnchorEl, setDateAnchorEl] = React.useState(null);

  const onDateClick = (event) => {
    setDateAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setDateAnchorEl(null);
  };

  const dateOpen = Boolean(dateAnchorEl);
  const dateId = dateOpen ? "simple-popover" : undefined;
  const reduxGoogleIntegration = useSelector(
    (state) => state.settings.googleIntegration
  );
  const totalOrders = useSelector((state) => state.orders.totalOrders);
  const reduxOrderStatuses = useSelector((state) => state.orders.orderStatuses);

  useEffect(() => {
    dispatch(getOrderStatuses());
  }, []);

  useEffect(() => {
    try {
      if (isArrayWithValues(reduxOrderStatuses)) {
        let allTotal = 0;

        const reports = reduxOrderStatuses?.map((o) => {
          allTotal += o?.total;
          return {
            label: o?.name,
            value: o?.slug,
            total: o?.total,
          };
        });

        // reports?.push({ label: "All", value: "", total: allTotal });

        setOrderStatuses([
          ...[{ label: "All", value: "", total: allTotal }],
          ...reports,
        ]);
      }
    } catch (e) {
      console.log(e);
    }
  }, [reduxOrderStatuses]);

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const filterClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const filterClose = () => {
    setAnchorEl(null);
  };

  const onFilterPress = (value) => {
    if (!value) onFilterClear();
    setParams((state) => ({ ...state, status: value }));
    setSelectedStatus(value);
    // filterClose();
    dispatch(
      getAllOrders({ ...params, status: value, ...defaultParams }, true)
    );
  };

  const onFilterClear = () => {
    let { status, ...otherParams } = params;
    setSelectedStatus("");
    setParams((state) => ({ ...otherParams }));
    dispatch(getAllOrders({ ...otherParams, ...defaultParams }, true));
  };

  function toISOStringLocal(date) {
    date = new Date(date);
    const pad = function (num) {
      return (num < 10 ? "0" : "") + num;
    };

    var year = date.getFullYear(),
      month = pad(date.getMonth() + 1),
      day = pad(date.getDate()),
      hour = pad(date.getHours()),
      minute = pad(date.getMinutes()),
      second = pad(date.getSeconds()),
      millisecond = pad(date.getMilliseconds());

    // Construct an ISO 8601 string in local time
    return `${year}-${month}-${day}T${hour}:${minute}:${second}.${millisecond}`;
  }

  const onDateSubmit = (params) => {
    // if (params.after) params.after = formatDate(params.after, "yyyy-mm-dd");
    // if (params.before) params.before = formatDate(params.before, "yyyy-mm-dd");

    if (params.after) {
      params.after = new Date(params.after);
      params.after = toISOStringLocal(params.after);
    }
    if (params.before) {
      params.before = new Date(params.before);
      params.before = toISOStringLocal(params.before);
    }

    setParams({ ...params, ...defaultParams });
    dispatch(getAllOrders({ ...params, ...defaultParams }, true));
  };

  return (
    <>
      <Toolbar
        sx={{
          pl: { sm: 2 },
          pr: { xs: 1, sm: 1 },
          ...(numSelected > 0 && {
            bgcolor: (theme) =>
              alpha(
                theme.palette.primary.main,
                theme.palette.action.activatedOpacity
              ),
          }),
        }}
      >
        {numSelected > 0 ? (
          <Typography
            sx={{ flex: "1 1 100%" }}
            color="inherit"
            variant="subtitle1"
            component="div"
          >
            {numSelected} selected
          </Typography>
        ) : (
          <Typography
            sx={{ flex: "1 1 100%" }}
            variant="h6"
            id="tableTitle"
            component="div"
          >
            Orders {totalOrders ? `(${totalOrders})` : ""}
          </Typography>
        )}
        <HorizontalStack sx={{ flex: 1 }} spacing={2}>
          <CustomDateRangeFilter
            dateRange={params}
            startDateLabel={"after"}
            endDateLabel={"before"}
            setDateRange={(dateRange) =>
              onDateSubmit({ ...defaultParams, ...params, ...dateRange })
            }
            mobileMode
          />
          <ExportAsSheets />
        </HorizontalStack>
        {/* <Popover
          id={dateId}
          open={dateOpen}
          anchorEl={dateAnchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          <DatePicker
            dateRanges={params}
            setDateRanges={onDateSubmit}
            onClose={handleClose}
          />
        </Popover> */}
        {/* <Box onClick={onDateClick} sx={{ cursor: "pointer" }}>
          <TextField
            size="small"
            label="Date"
            value={
              params.after || params.before
                ? formatDate(new Date(params.after), "yyyy-mm-dd") ==
                  formatDate(new Date(params.before), "yyyy-mm-dd")
                  ? `${formatDate(new Date(params.after), "relative_date")}`
                  : `${formatDate(
                      new Date(params.after),
                      "relative_date"
                    )} - ${formatDate(
                      new Date(params.before),
                      "relative_date"
                    )}`
                : ""
            }
            sx={{
              cursor: "pointer",
              pointerEvents: "none",
              minWidth:
                formatDate(new Date(params.after), "yyyy-mm-dd") ===
                formatDate(new Date(params.before), "yyyy-mm-dd")
                  ? "200px"
                  : "270px",
              transition: "all 0.3s",
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <CalendarToday />
                </InputAdornment>
              ),
            }}
          />
        </Box> */}

        {/* {
          numSelected > 0 && false ? (
            <Tooltip title="Delete">
              <IconButton>
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          ) : null
          // <Tooltip title="Filter list">
          //   <IconButton
          //     onClick={filterClick}
          //     aria-controls={filterOpen ? "account-menu" : undefined}
          //     aria-haspopup="true"
          //     aria-expanded={filterOpen ? "true" : undefined}
          //   >
          //     <FilterListIcon />
          //   </IconButton>
          // </Tooltip>
        } */}

        {/* <Menu
          anchorEl={anchorEl}
          id="account-menu"
          open={filterOpen}
          onClose={filterClose}
          // onClick={(e) => onFilterPress(e.target.value)}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 1.5,
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              "&:before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
          {allPaymentStatus.map((i) => (
            <MenuItem value={i.value} onClick={() => onFilterPress(i.value)}>
              {i.label}
            </MenuItem>
          ))}
        </Menu> */}
      </Toolbar>
      <Stack
        direction="row"
        sx={{
          backgroundColor: "background.paper",
          padding: "10px",
          borderBottomColor: "#e8e8e8",
          borderBottomWidth: 0.2,
          borderBottomStyle: "solid",
          overflow: "auto",
        }}
        spacing={4}
      >
        {orderStatuses.map((i) => {
          return (
            <Button
              variant={"text"}
              // variant={selectedTab !== i.value ? "outlined" : "contained"}
              sx={{
                backgroundColor:
                  selectedStatus === i.value ? "#e6e6e6" : "transparent",
                color: "#000",
                // color: selectedStatus === i.value ? "primary.main" : "#000",
                "&:hover": {
                  backgroundColor:
                    selectedStatus === i.value ? "#e6e6e6" : "transparent",
                },
                whiteSpace: "nowrap",
                // minWidth: "auto",
              }}
              onClick={() => onFilterPress(i.value)}
            >
              {i.label}
            </Button>
          );
        })}
      </Stack>
      {/* <Collapse in={Boolean(params.status)}>
        <Stack justifyContent="space-between" alignItems="flex-start" px={2}>
          <Chip
            label={params.status ? formatServerValue(params.status) : ""}
            onDelete={onFilterClear}
          />
        </Stack>
      </Collapse> */}
    </>
  );
};

const DatePicker = ({ dateRanges, setDateRanges, onClose }) => {
  const [tempDateRanges, setTempDateRanges] = useState({
    after: new Date(new Date().setDate(new Date().getDate() - 30)),
    before: new Date(),
  });

  const isIpad = useMediaQuery((theme) => theme.breakpoints.down("md"));

  useEffect(() => {
    if (isObjWithValues(dateRanges) && dateRanges.after && dateRanges.before)
      setTempDateRanges(dateRanges);
  }, [dateRanges]);

  const onSubmit = () => {
    setDateRanges({
      ...dateRanges,
      after: new Date(
        tempDateRanges.after.setDate(tempDateRanges.after.getDate() + 1)
      ),
      before: new Date(
        tempDateRanges.before.setDate(tempDateRanges.before.getDate() + 1)
      ),
    });
    onClose();
  };
  return (
    <Box sx={{ p: 3 }}>
      <DateRangePicker
        // editableDateInputs={true}
        // moveRangeOnFirstSelection={false}
        ranges={[
          {
            // ...tempDateRanges,
            startDate: tempDateRanges.after,
            endDate: tempDateRanges.before,
            key: "selection",
          },
        ]}
        onChange={({ selection }) => {
          setTempDateRanges((state) => ({
            after: selection ? selection.startDate : new Date(),
            before: selection ? selection.endDate : new Date(),
          }));
        }}
      />
      <Stack justifyContent={"flex-end"} direction="row" sx={{ mt: 3 }}>
        <Button onClick={onSubmit} sx={{ float: "right" }} variant="contained">
          Submit
        </Button>
      </Stack>
    </Box>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          {/* <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              "aria-label": "select all desserts",
            }}
          /> */}
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

const TruncatedText = ({ text, style, fontWeight }) => (
  <Tooltip title={text} style={{ ...(style || {}) }}>
    <Typography
      fontSize="13px"
      fontWeight={fontWeight}
      sx={{
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        maxWidth: "150px", // Adjust width to control the length of visible text
        display: "block",
        textAlign: "right",
      }}
    >
      {text}
    </Typography>
  </Tooltip>
);
