import {
  isArrayWithValues,
  isObjWithValues,
  validateNumber,
} from "jwero-javascript-utils";
import { currencySymbols } from "../../../pricing/Gold";
import {
  backendApiCall,
  getToken,
  getWebsite,
  settingsEndpoint,
} from "../../../../helper";
import { updateTanikaBulkProducts } from "../../../integrations/components/ornate/ornateHelper";
import { updateMultipleProductsInRedux } from "../../../../redux/actions/productActions";
import { backendEndpoints } from "../../../chat_new/apiFunctions";

const updateFacebookCatalogProducts = async ({
  integration,
  productsToUpdate = [],
  defaultCurrency,
  store_info,
  dispatch,
  onSuccess,
  onError,
  remove_products,
}) => {
  try {
    const catalogId = integration?.select_commerce_catalogs;
    let website = getWebsite();
    let token = getToken();

    if (!integration || !catalogId || !isArrayWithValues(productsToUpdate)) {
      return false;
    }

    let categoriesByCollections = await backendApiCall({
      endpoint: backendEndpoints.settings + "/categories_by_collections",
    });

    console.log(
      categoriesByCollections,
      "<<<< categoriesByCollections?.success"
    );

    if (categoriesByCollections?.status !== 200) throw new Error("");

    categoriesByCollections = isObjWithValues(categoriesByCollections?.data)
      ? categoriesByCollections?.data
      : {};

    const mappedProducts = mapProductsToFacebookFormat(productsToUpdate, {
      defaultCurrency,
      store_info,
      isMethodDelete: remove_products,
    });
    const MAX_BATCH_SIZE = 100;
    let batchResponses = [];

    for (let i = 0; i < mappedProducts.length; i += MAX_BATCH_SIZE) {
      const batch = mappedProducts.slice(i, i + MAX_BATCH_SIZE);
      const jweorBatch = productsToUpdate.slice(i, i + MAX_BATCH_SIZE);
      jweorBatch?.forEach((product) => {
        let { collections, categories } = product;
        if (isArrayWithValues(collections)) {
          for (let collection of collections) {
            let label = collection?.name || collection?.label;
            if (label) {
              if (!categoriesByCollections?.[label])
                categoriesByCollections[label] = [];
              let categoriesLabels = categories
                ?.map((i) => i?.name || i?.label)
                ?.filter(Boolean);
              categoriesByCollections[label] = categoriesByCollections[
                label
              ].concat([...new Set(categoriesLabels)]);

              categoriesByCollections[label] = [
                ...new Set(categoriesByCollections[label]),
              ];
            }
          }
        }
      });
      let facebookRes = await backendApiCall({
        endpoint: "social/commerce",
        params: {
          endpoint: `/${catalogId}/items_batch`,
        },
        method: "POST",
        data: {
          item_type: "PRODUCT_ITEM",
          requests: batch,
        },
      });
      if (isArrayWithValues(facebookRes?.handles)) {
        let batchJweroPayload = jweorBatch.map((i) => ({
          id: i?.id,
          meta_data: [
            {
              key: "_wc_facebook_sync_enabled",
              value: remove_products ? "" : "yes",
            },
          ],
        }));
        let jweroRes = await updateTanikaBulkProducts(
          website,
          token,
          batchJweroPayload
        );
        if (isArrayWithValues(jweroRes?.update))
          dispatch(updateMultipleProductsInRedux(jweroRes?.update));
      } else onError && onError();
    }

    await backendApiCall({
      endpoint: backendEndpoints.settings + "/categories_by_collections",
      method: "PUT",
      data: {
        data: categoriesByCollections,
      },
    });
    onSuccess && onSuccess();

    console.log("Updated facebook catalog products");
    return { success: true, res: batchResponses };
  } catch (e) {
    console.log(e);
    return { success: false };
  }
};
function mapProductsToFacebookFormat(
  products,
  { defaultCurrency, store_info, isMethodDelete = false }
) {
  return products.map((product) => {
    let attributes = product?.attributes;

    let collections = (
      attributes?.find((item) => item?.slug === "pa_collections")?.options || []
    ).join();
    let categories = (
      attributes?.find((item) => item?.slug === "pa_categories")?.options || []
    ).join();
    let purities = (
      attributes?.filter((item) => item?.slug === "pa_metal-purity")?.options ||
      []
    )?.join();
    let metalWeight = (
      attributes?.filter((item) => item?.slug === "pa_metal-weight")?.options ||
      []
    ).join();
    let tags = (product?.tags?.map((i) => i?.name || i?.label) || []).join();

    // Map the WooCommerce product fields to the format expected by Facebook
    // This is a simplified example, adjust according to your data structure
    let obj = {
      // method: isMethodDelete ? "DELETE" : "UPDATE",
      method: isMethodDelete ? "DELETE" : "CREATE",
      data: {
        // id: product.id,
        id: product.sku,
        sku: product.sku,
        title: product.name,
        condition: "new",
        description:
          product.description?.length === 0
            ? product.name
            : product.description,
        image_link: product.images?.[0]?.src,
        link: product.permalink,
        price: `${validateNumber(product.price)}` + " " + defaultCurrency,
        availability:
          product.stock_status === "instock" ? "in stock" : "out of stock",
        // Add any other fields required by Facebook Catalog
        custom_label_0: collections,
        custom_label_1: categories,
        custom_label_2: purities,
        custom_label_3: metalWeight,
        // custom_label_4: diamondW,
        custom_label_4: tags,
      },
    };

    if (store_info?.store_name) obj.data.brand = store_info?.store_name;
    if (product?.sale_price)
      obj.data.sale_price = product.sale_price + " " + defaultCurrency;

    return obj;
  });
}

export { updateFacebookCatalogProducts };
