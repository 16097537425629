import React, { useEffect, useState } from "react";
import {
  Box,
  IconButton,
  Stack,
  Typography,
  CircularProgress,
  TextField,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
} from "@mui/material";
import { Sync } from "@mui/icons-material";
import axios from "axios";
import {
  formatServerValue,
  isArrayWithValues,
  EmptyState,
} from "../../../../helper";
import { formatDate } from "jwero-javascript-utils";
import { useSelector } from "react-redux";

const CallLogs = () => {
  const today = new Date().toISOString().split("T")[0]; // Get today's date in YYYY-MM-DD format
  const yesterday = new Date(new Date().setDate(new Date().getDate() - 1))
    .toISOString()
    .split("T")[0];

  const [selectedDate, setSelectedDate] = useState(yesterday); // Default to today's date
  const [logs, setLogs] = useState([]);
  const [fetchingLogs, setFetchingLogs] = useState(false);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1); // Track the current page
  const [totalPages, setTotalPages] = useState(1); // Track the total number of pages

  const runoIntegration = useSelector(
    (state) => state.settings.runoIntegration
  );

  const getAllLogs = async (page = 1) => {
    setFetchingLogs(true);
    setError(null);

    try {
      const response = await axios.get("https://api.runo.in/v1/call/logs", {
        headers: {
          "Auth-Key": runoIntegration?.auth_key, // Replace with your actual Auth-Key
        },
        params: {
          date: selectedDate, // Use the selected date from state
          pageNo: page, // Fetch logs for the current page
          processName: "all", // or replace with a specific process name
          isLatestOnly: false,
        },
      });

      const { data, metadata } = response.data.data;
      setLogs(data);
      setCurrentPage(page);
      setTotalPages(
        metadata[0].total > 100 ? Math.ceil(metadata[0].total / 100) : 1
      );
    } catch (error) {
      console.error("Error fetching logs:", error);
      setError("Failed to fetch call logs");
    } finally {
      setFetchingLogs(false);
    }
  };

  useEffect(() => {
    getAllLogs(currentPage);
  }, [selectedDate]);

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      getAllLogs(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      getAllLogs(currentPage - 1);
    }
  };

  return (
    <Box p={4} sx={{ pt: 0 }}>
      <Stack
        direction={"row"}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <Typography variant="h6">Call Logs</Typography>
        <Stack direction="row" alignItems="center" spacing={2}>
          <TextField
            type="date"
            size="small"
            value={selectedDate}
            onChange={(e) => setSelectedDate(e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              max: yesterday, // Restrict to yesterday's date or earlier
            }}
          />
          <IconButton
            onClick={() => getAllLogs(currentPage)}
            disabled={fetchingLogs}
          >
            {fetchingLogs ? <CircularProgress size={24} /> : <Sync />}
          </IconButton>
        </Stack>
      </Stack>
      <Typography sx={{ fontStyle: "italic", mt: 2 }}>
        Find the call logs for a specific date
      </Typography>
      {error ? (
        <Typography color="error">{error}</Typography>
      ) : (
        <>
          <LogsList rows={logs} />
          <Stack direction="row" justifyContent="center" spacing={2} mt={2}>
            <Button
              variant="outlined"
              disabled={currentPage === 1}
              onClick={handlePreviousPage}
            >
              Previous
            </Button>
            <Typography variant="subtitle2" align="center">
              Page {currentPage} of {totalPages}
            </Typography>
            <Button
              variant="outlined"
              disabled={currentPage === totalPages}
              onClick={handleNextPage}
            >
              Next
            </Button>
          </Stack>
        </>
      )}
    </Box>
  );
};

export default CallLogs;

const LogsList = ({ rows }) => {
  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Called By</TableCell>
            <TableCell>Phone Number</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Duration</TableCell>
            <TableCell>Tag</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {isArrayWithValues(rows) ? (
            rows.map((row) => (
              <TableRow
                key={row.callId}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell>
                  <Typography
                    variant="h6"
                    sx={{
                      fontSize: "13px",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {row.name}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="subtitle2">{row.calledBy}</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="subtitle2">{row.phoneNumber}</Typography>
                </TableCell>
                <TableCell component="th" scope="row">
                  <Typography variant="subtitle2">
                    {formatServerValue(row.status)}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="subtitle2">{row.duration}</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="subtitle2">
                    {formatServerValue(row.tag)}
                  </Typography>
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow
              sx={{
                "&:last-child td, &:last-child th": {
                  border: 0,
                },
              }}
            >
              <TableCell colSpan={6}>
                <EmptyState text={"No Call Logs Found!"} />
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
