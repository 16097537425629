import { Box, Stack, Tab, Tabs, Typography } from "@mui/material";
import React, { useState } from "react";
import { TabPanel } from "../../../../helper";
import Integration from "./integration";
import { useSelector } from "react-redux";
import { ListItem } from "../meta/MetaOverview";
import CallLogs from "./CallLogs";
import CRMInteractions from "./CRMInteractions";

function Runo() {
  const [selectedTab, setSelectedTab] = useState("overview");

  const runoIntegration = useSelector(
    (state) => state.settings.runoIntegration
  );

  return (
    <Box sx={{ height: "100%", paddingRight: "15px" }}>
      <Stack
        sx={{
          position: "sticky",
          top: "0",
          backgroundColor: "#fff",
          zIndex: 13,
          margin: 0,
          padding: "12px",
        }}
      >
        <Stack
          direction="row"
          justifyContent={"space-between"}
          alignItems="center"
        >
          <Typography
            variant="h3"
            sx={{ display: "flex", alignItems: "center", gap: "10px" }}
          >
            <img
              src="/static/img/brands/runo.jpg"
              width="25px"
              height="22px"
              alt="Runo"
            />
            Runo
          </Typography>
        </Stack>
        <Tabs
          onChange={(e, newValue) => setSelectedTab(newValue)}
          value={selectedTab}
          sx={{ borderBottom: 1, borderColor: "divider" }}
        >
          <Tab label="Overview" value="overview" />
          <Tab label="Integration" value="integration" />
          {runoIntegration?.enabled === "yes" && (
            <Tab label="Call Logs" value="call_logs" />
          )}
          {runoIntegration?.enabled === "yes" && (
            <Tab label="Interactions" value="interactions" />
          )}
        </Tabs>
      </Stack>
      <TabPanel value={selectedTab} index={"overview"}>
        <Overview />
      </TabPanel>
      <TabPanel value={selectedTab} index={"integration"}>
        <Integration />
      </TabPanel>

      {runoIntegration?.enabled === "yes" && (
        <TabPanel value={selectedTab} index={"call_logs"}>
          <CallLogs />
        </TabPanel>
      )}
      {runoIntegration?.enabled === "yes" && (
        <TabPanel value={selectedTab} index={"interactions"}>
          <CRMInteractions />
        </TabPanel>
      )}
    </Box>
  );
}

export default Runo;

const benefits = [
  "✅ Ideal for Outbound, Sales and Telecalling process",
  "✅ Contact Ratio as high as 78%",
  "✅ Auto Dialer with CRM",
  "✅ Unlimited Call Recordings* of business calls.",
];

function Overview() {
  return (
    <Box sx={{ padding: "16px" }}>
      <Typography variant="subtitle1" sx={{ fontSize: "15px" }}>
        What's Runo?
      </Typography>
      <Typography variant="subtitle1" sx={{ fontSize: "15px", mt: 3 }}>
        SIM based Call Management CRM for small, medium, and large enterprises
      </Typography>
      <ListItem arr={benefits} />
    </Box>
  );
}
